const GreenBrandIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-565 -3441) translate(565 3441)">
        <circle cx="35" cy="35" r="35" fill="#DFF5EB" />
        <g fill="#41A476">
          <path
            d="M26.806 26.25c.5.007 1.019-.164 1.45-.608l6.746-6.973c2.058-2.126 2.489-6.16-.41-8.634-2.625-2.242-6.09-1.217-7.786.533-1.695-1.75-5.16-2.775-7.78-.533-2.898 2.475-2.467 6.508-.41 8.634l6.741 6.973c.43.444.943.608 1.45.608zm0-3.794l-5.886-6.09c-.895-.917-1.107-2.715.178-3.815.964-.82 2.468-.615 3.37.321l2.338 2.413 2.331-2.42c.903-.936 2.413-1.141 3.37-.321 1.292 1.1 1.074 2.898.185 3.821l-5.886 6.091zm-14.219 21.3c.602 0 1.094-.491 1.094-1.093 0-.513-.177-1.012-.492-1.408l-4.02-5.025c-.622-.772-.957-1.736-.957-2.734v-13.2c0-.793.534-1.524 1.313-1.668 1.046-.205 1.969.595 1.969 1.606v.595l.001.242.002.917.001.358.002.776.004 2.14.002.87.005 1.985.002.65.001.532c0 .52.192 1.012.533 1.408l5.23 6.33c1.21 1.463 3.568-.225 2.317-1.928l-3.383-4.58c-.554-.717-.417-1.75.3-2.303.739-.575 1.764-.397 2.304.3l4.901 6.371c.588.766.91 1.703.91 2.666v5.093c0 .602.492 1.094 1.093 1.094h2.188c.601 0 1.094-.492 1.094-1.094V37.57c0-.964.32-1.9.909-2.666l4.901-6.37c.54-.698 1.565-.869 2.304-.302.718.554.854 1.58.3 2.304l-3.383 4.58c-1.258 1.702 1.107 3.39 2.317 1.928l5.23-6.33c.341-.397.526-.889.533-1.408v-4.042-.438l.001-1.292v-.412l.003-1.134v-.334l.002-1.03v-.39c0-1.011.917-1.811 1.97-1.606.779.15 1.312.875 1.312 1.668v13.2c0 .991-.335 1.955-.957 2.728L40.43 41.24c-.321.404-.492.896-.492 1.408 0 .602.492 1.094 1.094 1.094h1.114c.335 0 .65-.15.855-.417l4.012-5.058c1.08-1.354 1.675-3.05 1.675-4.786V20.405c-.007-2.618-1.976-4.908-4.594-5.079-2.864-.191-5.257 2.085-5.257 4.908v.329l-.002.617v.263l-.002.598-.01 3.389c-2.036-1.354-4.955-1.06-6.616 1.093l-4.909 6.378c-.184.24-.341.506-.492.766-.157-.26-.307-.526-.492-.766l-4.908-6.378c-1.682-2.18-4.608-2.426-6.617-1.093l-.008-3.062-.002-.64v-.287l-.002-.499v-.206l-.002-.502c0-2.823-2.393-5.1-5.257-4.908-2.618.171-4.587 2.461-4.587 5.08v13.09c0 1.73.595 3.432 1.675 4.785l4.013 5.059c.205.26.526.417.854.417h1.114z"
            transform="translate(8.75 8.75)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default GreenBrandIcon
