const GreenCalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1026 -326) translate(974 172) translate(1 146) translate(51 8)">
        <circle cx="16" cy="16" r="16" fill="#DFF5EB" />
        <g fill="#41A476">
          <path
            d="M9.031 13.25H7.47c-.258 0-.469-.21-.469-.469V11.22c0-.258.21-.469.469-.469H9.03c.258 0 .469.21.469.469v1.562c0 .258-.21.469-.469.469zm4.219-.469V11.22c0-.258-.21-.469-.469-.469H11.22c-.258 0-.469.21-.469.469v1.562c0 .258.21.469.469.469h1.562c.258 0 .469-.21.469-.469zm3.75 0V11.22c0-.258-.21-.469-.469-.469H14.97c-.258 0-.469.21-.469.469v1.562c0 .258.21.469.469.469h1.562c.258 0 .469-.21.469-.469zm-3.75 3.75V14.97c0-.258-.21-.469-.469-.469H11.22c-.258 0-.469.21-.469.469v1.562c0 .258.21.469.469.469h1.562c.258 0 .469-.21.469-.469zm-3.75 0V14.97c0-.258-.21-.469-.469-.469H7.47c-.258 0-.469.21-.469.469v1.562c0 .258.21.469.469.469H9.03c.258 0 .469-.21.469-.469zm7.5 0V14.97c0-.258-.21-.469-.469-.469H14.97c-.258 0-.469.21-.469.469v1.562c0 .258.21.469.469.469h1.562c.258 0 .469-.21.469-.469zm3.75-10.156v13.75c0 1.035-.84 1.875-1.875 1.875H5.125c-1.035 0-1.875-.84-1.875-1.875V6.375c0-1.035.84-1.875 1.875-1.875H7V2.469C7 2.21 7.21 2 7.469 2H9.03c.258 0 .469.21.469.469V4.5h5V2.469c0-.258.21-.469.469-.469h1.562c.258 0 .469.21.469.469V4.5h1.875c1.035 0 1.875.84 1.875 1.875zm-1.875 13.516V8.25H5.125v11.64c0 .13.105.235.234.235h13.282c.129 0 .234-.105.234-.234z"
            transform="translate(4 4)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default GreenCalendarIcon
