const CarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#656B72">
        <path
          d="M2.792 12.667c.345 0 .625-.28.625-.625v-.625h9.166v.625c0 .345.28.625.625.625h.417c.345 0 .625-.28.625-.625V8.5c0-.458-.131-.881-.347-1.25h.295c.143 0 .268-.097.303-.236l.156-.625c.05-.198-.1-.389-.303-.389h-1.36l-.816-2.028c-.318-.789-1.083-1.305-1.933-1.305h-4.49c-.85 0-1.616.516-1.933 1.305L3.005 6H1.646c-.203 0-.353.191-.303.388l.156.625c.033.13.169.237.303.237h.295c-.216.369-.347.792-.347 1.25v3.542c0 .345.28.625.625.625h.417zM11.647 6H4.353l.629-1.561c.127-.317.43-.522.773-.522h4.49c.342 0 .646.205.773.522l.629 1.56zm.936 4.167H3.417c-.23 0-.417-.187-.417-.417V8.5c0-.69.56-1.25 1.25-1.25h7.5c.69 0 1.25.56 1.25 1.25v1.25c0 .23-.187.417-.417.417zM5.917 9.12c0-.498-.75-1.246-1.25-1.246s-.834.332-.834.83c0 .5.334.832.834.832.5 0 1.25.083 1.25-.416zm5.416.416c.5 0 .834-.333.834-.831 0-.499-.334-.831-.834-.831-.5 0-1.25.748-1.25 1.246 0 .499.75.416 1.25.416z"
          transform="translate(-413 -1162) translate(45 818) translate(344 75) translate(24 269)"
        />
      </g>
    </g>
  </svg>
)

export default CarIcon
