const GreenCarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-277 -2666) translate(160 2566) translate(0 100) translate(117)">
        <circle cx="40" cy="40" r="40" fill="#DFF5EB" />
        <g fill="#41A476">
          <path
            d="M10.468 47.5c1.294 0 2.344-1.05 2.344-2.344v-2.343h34.375v2.343c0 1.294 1.05 2.344 2.344 2.344h1.562c1.294 0 2.344-1.05 2.344-2.344V31.875c0-1.718-.492-3.305-1.3-4.687h1.105c.538 0 1.007-.366 1.137-.887l.586-2.344c.184-.74-.375-1.456-1.138-1.456h-5.099l-3.062-7.605C44.476 11.937 41.607 10 38.42 10H21.58c-3.188 0-6.057 1.937-7.247 4.895L11.27 22.5H6.172c-.762 0-1.322.717-1.136 1.456l.586 2.344c.122.49.632.887 1.136.888h1.105c-.809 1.382-1.3 2.97-1.3 4.686v13.282c0 1.294 1.049 2.344 2.343 2.344h1.562zm33.207-25h-27.35l2.356-5.855c.479-1.189 1.617-1.957 2.899-1.957h16.84c1.282 0 2.42.768 2.898 1.957l2.357 5.854zm3.512 15.625H12.812c-.861 0-1.562-.701-1.562-1.562v-4.688c0-2.585 2.102-4.687 4.687-4.687h28.125c2.585 0 4.688 2.102 4.688 4.687v4.688c0 .86-.701 1.562-1.563 1.562zm-25-3.92c0-1.87-2.812-4.675-4.687-4.675-1.875 0-3.125 1.246-3.125 3.117 0 1.87 1.25 3.116 3.125 3.116s4.687.311 4.687-1.559zM42.5 35.762c1.875 0 3.125-1.246 3.125-3.116s-1.25-3.117-3.125-3.117-4.688 2.805-4.688 4.674c0 1.87 2.813 1.559 4.688 1.559z"
            transform="translate(10 10)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default GreenCarIcon
