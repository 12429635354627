const FiltersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <g fill="#1B4832">
        <path d="M8.25 11.25c.98 0 1.814.626 2.122 1.5H16.1c.22 0 .4.18.4.4v.7c0 .22-.18.4-.4.4h-5.728c-.31.874-1.142 1.5-2.122 1.5s-1.813-.626-2.122-1.5H1.9c-.22 0-.4-.18-.4-.4v-.7c0-.22.18-.4.4-.4h4.228c.308-.874 1.142-1.5 2.122-1.5zm0 1.2c-.58 0-1.05.47-1.05 1.05 0 .58.47 1.05 1.05 1.05.58 0 1.05-.47 1.05-1.05 0-.58-.47-1.05-1.05-1.05zm4.5-5.7c.98 0 1.814.626 2.122 1.5H16.1c.22 0 .4.18.4.4v.7c0 .22-.18.4-.4.4h-1.228c-.31.874-1.142 1.5-2.122 1.5s-1.813-.626-2.122-1.5H1.9c-.22 0-.4-.18-.4-.4v-.7c0-.22.18-.4.4-.4h8.728c.308-.874 1.142-1.5 2.122-1.5zm0 1.2c-.58 0-1.05.47-1.05 1.05 0 .58.47 1.05 1.05 1.05.58 0 1.05-.47 1.05-1.05 0-.58-.47-1.05-1.05-1.05zM6 2.25c.98 0 1.814.626 2.122 1.5H16.1c.22 0 .4.18.4.4v.7c0 .22-.18.4-.4.4H8.122c-.31.874-1.142 1.5-2.122 1.5s-1.813-.626-2.122-1.5H1.9c-.22 0-.4-.18-.4-.4v-.7c0-.22.18-.4.4-.4h1.978C4.186 2.877 5.02 2.25 6 2.25zm0 1.2c-.58 0-1.05.47-1.05 1.05 0 .58.47 1.05 1.05 1.05.58 0 1.05-.47 1.05-1.05 0-.58-.47-1.05-1.05-1.05z" transform="translate(-318 -1991) translate(0 1972) translate(187) translate(131 19)" />
      </g>
    </g>
  </svg>
)

export default FiltersIcon
