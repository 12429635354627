const GreenTransmissionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g fill="#41A476">
        <path
          d="M6.457 17.48h7.693v-.694H6.457v.694zm2.408-5.944h2.878c.256 0 .49.132.582.33l1.153 2.527.555 1.216H6.578l.554-1.216 1.154-2.528c.09-.198.322-.33.579-.33zm1.859-5.116l-.001.001c-.042.117-.219.202-.421.202-.083 0-.166-.014-.234-.04-.066-.028-.15-.077-.182-.157l-.005-.011c-.482-1.304-.716-2.222-.716-2.804 0-.423.51-.768 1.137-.768.628 0 1.14.343 1.14.765 0 .574-.235 1.493-.718 2.812zm.095 1.373v2.566h-.963V7.793c.185.005.4.006.485.006.084 0 .296 0 .478-.006zm4.704 7.706c-.013-.03-1.32-2.9-1.843-4.045-.288-.634-1.023-1.067-1.847-1.096V7.196c.13-.14.223-.289.274-.442.52-1.415.783-2.473.783-3.146 0-1.07-1.161-1.941-2.588-1.941-1.426 0-2.585.87-2.585 1.941 0 .473.135 1.403.78 3.148.06.159.15.306.273.44v3.165c-.82.03-1.555.463-1.846 1.095-.523 1.156-1.813 3.977-1.842 4.04l-.002.003c-.071.158-.095.79-.071 1.877v.003c.001.526.529.954 1.177.954h8.236c.648 0 1.175-.428 1.176-.954.013-.611.021-1.667-.075-1.88z"
          transform="translate(-975 -468) translate(974 172) translate(1 296)"
        />
      </g>
    </g>
  </svg>
)

export default GreenTransmissionIcon
