const GreenUploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40">
    <g fill="none" fillRule="evenodd">
      <g fill="#41A476">
        <path d="M31.7 7.667c.442 0 .8.358.8.8v1.4c0 .441-.358.8-.8.8H21.413l9.194 9.12c.306.306.306.8 0 1.107l-1.289 1.289c-.306.306-.8.306-1.107 0l-6.038-5.979-.002 16.357c0 .423-.317.773-.727.825l-.104.006h-1.843c-.424 0-.774-.317-.825-.727l-.007-.104V16.203l-6.04 5.98c-.306.306-.8.306-1.106 0l-1.29-1.29c-.305-.305-.305-.8 0-1.106l9.193-9.12H9.133c-.441 0-.8-.359-.8-.8v-1.4c0-.442.359-.8.8-.8H31.7z" transform="translate(-395 -717) translate(160 709) translate(235.743 8)" />
      </g>
    </g>
  </svg>
)

export default GreenUploadIcon
