const RedFileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#de0000">
        <g>
          <g>
            <g>
              <path d="M18.125 22C19.161 22 20 21.16 20 20.125V7.152c0-.43-.246-1.023-.55-1.326l-3.276-3.277C15.871 2.246 15.277 2 14.848 2H6.875C5.839 2 5 2.84 5 3.875v16.25C5 21.161 5.84 22 6.875 22h11.25zm0-1.875H6.875V3.875h6.25v4.063c0 .517.42.937.938.937h4.062v11.25zM17.973 7H15V4.027L17.973 7zm-6.456 11.114l5.56-5.515c.183-.182.184-.479.002-.663l-.88-.887c-.183-.184-.48-.185-.663-.003l-4.34 4.304-1.722-1.737c-.182-.183-.479-.185-.663-.002l-.887.88c-.184.182-.185.48-.003.663l2.933 2.957c.183.184.48.185.663.003z" transform="translate(-221 -563) translate(160 496) translate(53 56) translate(8 11)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default RedFileIcon
