const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <g fill="#41A476">
        <path
          d="M9 1.5c4.142 0 7.5 3.358 7.5 7.5 0 4.142-3.358 7.5-7.5 7.5-4.142 0-7.5-3.358-7.5-7.5 0-4.142 3.358-7.5 7.5-7.5zm0 1.452C5.657 2.952 2.952 5.657 2.952 9S5.657 15.048 9 15.048 15.048 12.343 15.048 9 12.343 2.952 9 2.952zm4.24 3.94c.141.141.14.371-.002.512l-5.22 5.178c-.142.141-.371.14-.513-.002L4.76 9.812c-.141-.142-.14-.372.002-.513l.687-.682c.142-.14.372-.14.513.003l1.809 1.822 4.274-4.24c.143-.141.372-.14.514.002l.681.687z"
          transform="translate(-86 -2134) translate(0 1936) translate(86 96) translate(0 99) translate(0 3)"
        />
      </g>
    </g>
  </svg>
)

export default SuccessIcon
