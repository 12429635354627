const MessageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-275 -3084) translate(275 3084)">
          <circle cx="40" cy="40" r="40" fill="#41A476" />
          <g fill="#FFF">
            <path
              d="M22.617 55c.211 0 .43-.062.624-.211l10.978-8.226h12.656c3.102 0 5.625-2.523 5.625-5.625V15.625c0-3.102-2.523-5.625-5.625-5.625h-33.75C10.022 10 7.5 12.523 7.5 15.625v25.313c0 3.102 2.522 5.625 5.625 5.625h8.437v7.382c0 .624.51 1.055 1.055 1.055zm3.164-7.383v-5.273H13.125c-.773 0-1.406-.633-1.406-1.406V15.625c0-.773.633-1.406 1.406-1.406h33.75c.773 0 1.406.633 1.406 1.406v25.313c0 .773-.633 1.406-1.406 1.406H32.812l-1.125.843-5.906 4.43zM18.75 31.094c1.556 0 2.812-1.257 2.812-2.813 0-1.555-1.256-2.812-2.812-2.812-1.556 0-2.813 1.257-2.813 2.812 0 1.556 1.257 2.813 2.813 2.813zm11.25 0c1.556 0 2.812-1.257 2.812-2.813 0-1.555-1.256-2.812-2.812-2.812-1.556 0-2.813 1.257-2.813 2.812 0 1.556 1.257 2.813 2.813 2.813zm11.25 0c1.556 0 2.812-1.257 2.812-2.813 0-1.555-1.256-2.812-2.812-2.812-1.556 0-2.813 1.257-2.813 2.812 0 1.556 1.257 2.813 2.813 2.813z"
              transform="translate(10 10)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default MessageIcon
