const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#656B72">
        <path
          d="M6.02 8.833H4.98c-.173 0-.313-.14-.313-.312V7.479c0-.172.14-.312.312-.312h1.042c.172 0 .312.14.312.312v1.042c0 .172-.14.312-.312.312zm2.813-.312V7.479c0-.172-.14-.312-.312-.312H7.479c-.172 0-.312.14-.312.312v1.042c0 .172.14.312.312.312h1.042c.172 0 .312-.14.312-.312zm2.5 0V7.479c0-.172-.14-.312-.312-.312H9.979c-.172 0-.312.14-.312.312v1.042c0 .172.14.312.312.312h1.042c.172 0 .312-.14.312-.312zm-2.5 2.5V9.979c0-.172-.14-.312-.312-.312H7.479c-.172 0-.312.14-.312.312v1.042c0 .172.14.312.312.312h1.042c.172 0 .312-.14.312-.312zm-2.5 0V9.979c0-.172-.14-.312-.312-.312H4.979c-.172 0-.312.14-.312.312v1.042c0 .172.14.312.312.312h1.042c.172 0 .312-.14.312-.312zm5 0V9.979c0-.172-.14-.312-.312-.312H9.979c-.172 0-.312.14-.312.312v1.042c0 .172.14.312.312.312h1.042c.172 0 .312-.14.312-.312zm2.5-6.771v9.167c0 .69-.56 1.25-1.25 1.25H3.417c-.69 0-1.25-.56-1.25-1.25V4.25c0-.69.56-1.25 1.25-1.25h1.25V1.646c0-.172.14-.313.312-.313h1.042c.172 0 .312.141.312.313V3h3.334V1.646c0-.172.14-.313.312-.313h1.042c.172 0 .312.141.312.313V3h1.25c.69 0 1.25.56 1.25 1.25zm-1.25 9.01V5.5H3.417v7.76c0 .086.07.157.156.157h8.854c.086 0 .156-.07.156-.157z"
          transform="translate(-413 -1182) translate(45 818) translate(344 75) translate(24 289)"
        />
      </g>
    </g>
  </svg>
)

export default CalendarIcon
