const GreenPowerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g fill="#41A476">
        <path
          d="M16.505 16c.307 0 .6-.158.755-.43.682-1.203 1.073-2.601 1.073-4.094 0-4.497-3.544-8.143-7.916-8.143-4.373 0-7.917 3.646-7.917 8.143 0 1.493.392 2.891 1.074 4.094.154.272.448.43.754.43h12.177zm-.253-1.357H4.582c-.5-.972-.763-2.06-.763-3.167 0-3.742 2.96-6.786 6.598-6.786 3.637 0 6.597 3.044 6.597 6.786 0 1.106-.263 2.195-.762 3.167zM10.27 7.827l.446-1.378c.032-.097.087-.18.135-.266-.13-.078-.273-.135-.434-.135-.486 0-.88.405-.88.904 0 .447.32.8.733.875zm1.662 5.459c.151-.267.244-.574.244-.905 0-.55-.243-1.037-.62-1.369l1.247-3.845c.114-.355-.073-.74-.418-.858-.35-.12-.72.074-.836.429l-1.246 3.845c-.917.061-1.646.838-1.646 1.798 0 .331.093.638.245.905h3.03zM7.338 9.214c.486 0 .88-.405.88-.904 0-.5-.394-.905-.88-.905s-.88.405-.88.905.394.904.88.904zm6.157 0c.486 0 .88-.405.88-.904 0-.447-.32-.801-.734-.875l-.004.018-.536 1.654c.12.063.25.108.394.108zM6.02 12.382c.485 0 .88-.405.88-.905s-.395-.905-.88-.905c-.486 0-.88.406-.88.905 0 .5.394.905.88.905zm8.796 0c.486 0 .88-.405.88-.905s-.394-.905-.88-.905-.88.406-.88.905c0 .5.394.905.88.905z"
          transform="translate(-975 -496) translate(974 172) translate(1 324)"
        />
      </g>
    </g>
  </svg>
)

export default GreenPowerIcon
