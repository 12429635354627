const ArrowRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <g fill="#262626">
        <path
          d="M9.603 3.75c.207 0 .374.168.374.374v7.36l2.718-2.69c.138-.137.36-.137.498 0l.58.58c.138.138.138.36 0 .498l-4.336 4.301c-.137.138-.36.138-.498 0l-4.336-4.3c-.137-.138-.137-.36 0-.499l.58-.58c.138-.137.36-.137.498 0l2.718 2.69v-7.36c0-.18.129-.331.3-.366l.075-.008z"
          transform="translate(-1377 -1392) translate(45 1377) translate(1146 14) translate(186 1) rotate(-90 9.188 9.013)"
        />
      </g>
    </g>
  </svg>
)

export default ArrowRightIcon
