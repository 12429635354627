const ClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      <g fill="#262626">
        <path
          d="M12.272 12.673c.228-.214.228-.56 0-.773l-1.033-.968c.753-.875 1.203-1.99 1.203-3.201.002-.761-.175-1.483-.491-2.13.542-.468.882-1.137.882-1.883 0-1.41-1.217-2.551-2.722-2.551-1.1 0-2.049.61-2.477 1.492-.42-.046-.848-.046-1.268 0-.428-.882-1.376-1.492-2.477-1.492-1.505 0-2.722 1.141-2.722 2.551 0 .746.34 1.415.882 1.882-.316.648-.493 1.37-.493 2.129 0 1.212.452 2.326 1.203 3.2l-1.033.969c-.229.214-.229.56 0 .772.228.215.598.215.824 0l1.033-.968c1.98 1.497 4.83 1.513 6.83 0l1.035.97c.228.215.598.215.824 0zm-.946-8.045c-.636-.78-1.507-1.381-2.51-1.714.277-.394.753-.654 1.295-.654.858 0 1.556.654 1.556 1.458 0 .345-.13.661-.34.91zm-8.65 0c-.214-.249-.343-.565-.343-.91 0-.804.698-1.458 1.556-1.458.542 0 1.018.26 1.298.654-1.006.333-1.877.934-2.511 1.714zM7 11.738c-2.365 0-4.278-1.793-4.278-4.01 0-2.21 1.906-4.01 4.278-4.01 2.367 0 4.278 1.796 4.278 4.01 0 2.217-1.913 4.01-4.278 4.01zm1.531-2.25l.44-.602c.09-.125.059-.294-.075-.38L7.68 7.721V5.268c0-.15-.132-.274-.292-.274H6.61c-.16 0-.292.123-.292.274V8.25c0 .091.047.176.127.226l1.677 1.082c.134.084.316.052.408-.07z"
          transform="translate(-413 -1472) translate(45 1377) translate(344 75) translate(16 16) translate(8 4)"
        />
      </g>
    </g>
  </svg>
)

export default ClockIcon
