const GreenSupportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="80"
    height="80"
    viewBox="0 0 80 80"
  >
    <defs>
      <path id="ha99th8kfb" d="M0 0.425L45 0.425 45 50.727 0 50.727z" />
      <path id="w65j6s05nd" d="M0 51.12L45 51.12 45 0.819 0 0.819z" />
      <filter id="vadlqil5za">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.254902 0 0 0 0 0.643137 0 0 0 0 0.462745 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1083 -2666) translate(160 2566) translate(0 100) translate(806) translate(117)">
        <circle cx="40" cy="40" r="40" fill="#DFF5EB" />
        <g filter="url(#vadlqil5za)" transform="translate(10 10)">
          <g transform="translate(7.5 2.5)">
            <g transform="translate(0 .393)">
              <mask id="34k0nzljnc" fill="#fff">
                <use xlinkHref="#ha99th8kfb" />
              </mask>
              <path
                fill="#1D1D1B"
                d="M19.964 34.507c1.298.398 2.644.603 4.008.61.355 0 .711-.016 1.064-.046v1.109c0 1.398-1.137 2.536-2.536 2.536-1.398 0-2.536-1.138-2.536-2.536v-1.673zm13.91-26.338c-.892.172-1.582.342-2.104.475-1.578.446-3.214.651-4.862.618-1.607.043-3.175-.21-4.68-.742-1.328-.485-2.721-.731-4.14-.731h-.007c-1.037 0-1.881.843-1.881 1.882v2.536h-2.127V8.198c.007-2.204 1.806-4.003 4.008-4.01h15.792v3.98zM14.526 24.33c.194-.076.384-.165.57-.263l3.128 3.142c.357.346.828.538 1.33.543h4.826v-3.764h-4.054l-2.575-2.577c.476-.892.73-1.888.738-2.907-.003-.877-.193-1.742-.555-2.534h.146c1.039 0 1.883-.845 1.883-1.882V11.77c.367.091.733.202 1.093.334 1.894.649 3.867.947 5.854.921 1.957.037 3.913-.212 5.793-.734.369-.095.753-.19 1.17-.279v9.444c.034 4.983-3.666 9.224-8.609 9.863-4.824.623-9.37-2.363-10.738-6.988zm.2-5.824c0 1.398-1.137 2.536-2.534 2.536-1.396-.004-2.535-1.143-2.539-2.537 0-1.398 1.139-2.535 2.537-2.535 1.399 0 2.537 1.137 2.537 2.536zM36 37.49L28.8 34.863v-.65c5.292-1.995 8.831-7.086 8.837-12.76V2.307c0-1.038-.845-1.882-1.882-1.882H18.08c-4.285 0-7.772 3.487-7.772 7.773v4.315c-2.692.834-4.493 3.328-4.416 6.172.077 2.863 2.035 5.27 4.794 5.939.596 2.453 1.841 4.695 3.62 6.503.588.57 1.223 1.099 1.893 1.57v2.166L9.004 37.49C3.618 39.436 0 44.592 0 50.318v.41h3.763v-.41c.001-4.147 2.62-7.88 6.521-9.291l6.457-2.352c.977 2.292 3.241 3.806 5.76 3.806 2.517 0 4.78-1.514 5.759-3.806l6.457 2.352c3.9 1.41 6.519 5.144 6.52 9.291v.41H45v-.41C45 44.592 41.383 39.437 36 37.49z"
                mask="url(#34k0nzljnc)"
              />
            </g>
            <mask id="rcpjm64ane" fill="#fff">
              <use xlinkHref="#w65j6s05nd" />
            </mask>
            <path
              fill="#1D1D1B"
              d="M20.618 19.309L24.382 19.309 24.382 15.545 20.618 15.545zM27.982 19.31L31.745 19.31 31.745 15.546 27.982 15.546z"
              mask="url(#rcpjm64ane)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default GreenSupportIcon
