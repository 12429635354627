const GreyCarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-334 -1489) translate(334 1489)">
        <circle cx="16" cy="16" r="16" fill="#C1C7CF" />
        <g fill="#464B52">
          <path
            d="M4.187 19c.518 0 .938-.42.938-.937v-.938h13.75v.938c0 .517.42.937.937.937h.625c.518 0 .938-.42.938-.937V12.75c0-.687-.197-1.322-.52-1.875h.442c.215 0 .402-.146.454-.355l.235-.937c.074-.296-.15-.583-.455-.583h-2.04l-1.225-3.042C17.791 4.775 16.643 4 15.368 4H8.632c-1.275 0-2.423.775-2.899 1.958L4.508 9H2.47c-.305 0-.529.287-.455.582l.235.938c.049.196.252.355.454.355h.442c-.323.553-.52 1.188-.52 1.875v5.313c0 .517.42.937.937.937h.625zM17.47 9H6.53l.943-2.342c.19-.476.646-.783 1.159-.783h6.736c.513 0 .968.307 1.16.783L17.47 9zm1.405 6.25H5.125c-.345 0-.625-.28-.625-.625V12.75c0-1.034.84-1.875 1.875-1.875h11.25c1.034 0 1.875.841 1.875 1.875v1.875c0 .345-.28.625-.625.625zm-10-1.568c0-.748-1.125-1.87-1.875-1.87s-1.25.499-1.25 1.247c0 .748.5 1.246 1.25 1.246s1.875.125 1.875-.623zm8.125.623c.75 0 1.25-.498 1.25-1.246s-.5-1.247-1.25-1.247-1.875 1.122-1.875 1.87 1.125.623 1.875.623z"
            transform="translate(4 4)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default GreyCarIcon
