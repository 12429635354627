const ArrowLeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <g fill="#262626">
        <path
          d="M11.804 5c.275 0 .499.224.499.499v9.813l3.624-3.586c.183-.184.48-.184.664 0l.773.773c.184.184.184.48 0 .664l-5.781 5.735c-.184.183-.48.183-.664 0l-5.781-5.735c-.184-.183-.184-.48 0-.664l.773-.773c.184-.184.48-.184.664 0l3.623 3.587.001-9.814c0-.245.177-.449.41-.49l.09-.009z"
          transform="translate(-48 -331) translate(45 328) matrix(-1 0 0 1 23 0) rotate(-90 11.251 12.018)"
        />
      </g>
    </g>
  </svg>
)

export default ArrowLeftIcon
