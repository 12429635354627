const GreenPocketIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1312 -326) translate(974 172) translate(292 146) translate(46.466 8)">
        <circle cx="16" cy="16" r="16" fill="#DFF5EB" />
        <g fill="#41A476">
          <path
            d="M19.5 20.273c1.38 0 2.5-1.105 2.5-2.468V8.552c0-1.363-1.12-2.468-2.5-2.468v-.616C19.5 4.105 18.38 3 17 3H5.75C3.679 3 2 4.657 2 6.701v9.87c0 2.045 1.679 3.702 3.75 3.702H19.5zm0-1.85H5.75c-1.034 0-1.875-.831-1.875-1.852v-9.87c0-1.02.841-1.85 1.875-1.85H17c.345 0 .625.276.625.617V6.7H6.375c-.345 0-.625.276-.625.617 0 .341.28.617.625.617H19.5c.345 0 .625.277.625.617v9.253c0 .34-.28.617-.625.617zm-2.5-4.32c.69 0 1.25-.552 1.25-1.233s-.56-1.234-1.25-1.234-1.25.553-1.25 1.234c0 .681.56 1.234 1.25 1.234z"
            transform="translate(4 4)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default GreenPocketIcon
