const GreenFuelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g fill="#41A476">
        <path
          d="M12.578 17.5c.129 0 .235-.105.235-.234v-.938c0-.129-.106-.234-.235-.234h-.703v-4.688h.234c.648 0 1.172.525 1.172 1.172v.815c0 1.104.791 2.109 1.89 2.223 1.26.126 2.329-.864 2.329-2.1V6.97c0-.498-.196-.976-.548-1.327l-2.537-2.538c-.09-.09-.24-.09-.331 0l-.662.663c-.09.09-.09.24 0 .33l1.265 1.27v1.819c0 .823.613 1.502 1.407 1.617v4.71c0 .387-.317.704-.703.704-.387 0-.704-.317-.704-.703v-.938c0-1.424-1.154-2.578-2.578-2.578h-.234V3.906c0-.776-.63-1.406-1.406-1.406H4.844c-.777 0-1.407.63-1.407 1.406v12.188h-.703c-.129 0-.234.105-.234.234v.938c0 .129.105.234.234.234h9.844zm-2.11-9.375H4.845V3.906h5.625v4.219zm0 7.969H4.845V9.53h5.625v6.563z"
          transform="translate(-975 -440) translate(974 172) translate(1 268)"
        />
      </g>
    </g>
  </svg>
)

export default GreenFuelIcon
