const GreenClickIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="80"
    height="80"
    viewBox="0 0 80 80"
  >
    <defs>
      <path id="j7cczu7vbb" d="M0 0.22L11.433 0.22 11.433 4.167 0 4.167z" />
      <path id="xzcebcwtwd" d="M0.517 0.463L31.653 0.463 31.653 31.579 0.517 31.579z" />
      <path id="vgq0d26i8f" d="M0.589 0.35L4.536 0.35 4.536 11.782 0.589 11.782z" />
      <filter id="ppl26g0uja">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.254902 0 0 0 0 0.643137 0 0 0 0 0.462745 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-680 -2666) translate(160 2566) translate(0 100) translate(403) translate(117)">
        <circle cx="40" cy="40" r="40" fill="#DFF5EB" />
        <g filter="url(#ppl26g0uja)" transform="translate(10 10)">
          <g>
            <g transform="translate(5 5) translate(0 16.876)">
              <mask id="xiab0x1u1c" fill="#fff">
                <use xlinkHref="#j7cczu7vbb" />
              </mask>
              <path
                fill="#1D1D1B"
                d="M9.46.22H1.972C.885.22 0 1.106 0 2.194s.885 1.973 1.973 1.973H9.46c1.088 0 1.974-.885 1.974-1.973S10.547.22 9.459.22"
                mask="url(#xiab0x1u1c)"
              />
            </g>
            <g transform="translate(5 5) translate(15.072 16.158)">
              <mask id="17b24do8we" fill="#fff">
                <use xlinkHref="#xzcebcwtwd" />
              </mask>
              <path
                fill="#1D1D1B"
                d="M24.8 12.702l-11.483 11.49L5.965 5.81 24.8 12.702zm6.273 15.503l-8.13-8.11 6.838-6.792c.48-.478.68-1.159.531-1.823-.15-.656-.614-1.189-1.25-1.427L3.162.577c-.73-.26-1.548-.069-2.088.487-.538.556-.704 1.38-.42 2.107l10.105 25.262c.247.623.79 1.072 1.49 1.207.13.017.263.02.394.005.507-.012.984-.214 1.35-.575l6.182-6.175 8.104 8.104c.374.376.882.58 1.4.58.17 0 .34-.022.51-.067.68-.183 1.215-.718 1.397-1.398.182-.68-.014-1.411-.513-1.909z"
                mask="url(#17b24do8we)"
              />
            </g>
            <g transform="translate(5 5) translate(15.79 .368)">
              <mask id="mtbvni13hg" fill="#fff">
                <use xlinkHref="#vgq0d26i8f" />
              </mask>
              <path
                fill="#1D1D1B"
                d="M2.566 11.782c1.08-.006 1.964-.89 1.97-1.974V2.323c0-1.088-.886-1.974-1.974-1.974S.59 1.235.59 2.323v7.485c0 1.089.885 1.974 1.977 1.974"
                mask="url(#mtbvni13hg)"
              />
            </g>
            <path
              fill="#1D1D1B"
              d="M7.582 5.233l-.076-.067c-.785-.584-1.862-.513-2.561.172-.7.686-.794 1.76-.225 2.557l6.703 6.717c.365.374.875.588 1.399.588.522 0 1.032-.214 1.388-.578.374-.366.588-.876.588-1.398 0-.524-.214-1.033-.584-1.396L7.582 5.233z"
              transform="translate(5 5)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default GreenClickIcon
