const ArrowDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g fill="#2EC58D">
        <path
          d="M15.557 20.781L7.85 13.135c-.245-.244-.245-.64 0-.885l1.031-1.031c.245-.245.64-.245.886 0L16 17.39l6.234-6.172c.245-.245.641-.245.886 0l1.031 1.031c.245.245.245.64 0 .885l-7.708 7.646c-.245.245-.64.245-.886 0z"
          transform="translate(-708 -794) translate(708 794)"
        />
      </g>
    </g>
  </svg>
)

export default ArrowDownIcon
