const GreenCheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <g fill="#41A476">
        <path
          d="M6.617 13.9L2.438 9.72c-.25-.25-.25-.657 0-.908l.91-.91c.25-.25.658-.25.909 0l2.814 2.815 6.03-6.029c.25-.25.658-.25.909 0l.909.91c.25.25.25.658 0 .909l-7.393 7.392c-.251.251-.658.251-.91 0z"
          transform="translate(-975 -644) translate(974 172) translate(1 472)"
        />
      </g>
    </g>
  </svg>
)

export default GreenCheckIcon
