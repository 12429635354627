const CommentsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#656B72">
        <g>
          <g>
            <g>
              <path
                d="M6.031 14.667c.057 0 .115-.017.167-.057l2.927-2.193H12.5c.827 0 1.5-.673 1.5-1.5v-6.75c0-.828-.673-1.5-1.5-1.5h-9c-.827 0-1.5.672-1.5 1.5v6.75c0 .827.673 1.5 1.5 1.5h2.25v1.968c0 .167.136.282.281.282zm.844-1.97v-1.405H3.5c-.206 0-.375-.17-.375-.375v-6.75c0-.207.169-.375.375-.375h9c.206 0 .375.168.375.375v6.75c0 .206-.169.375-.375.375H8.75l-.3.225-1.575 1.18zM5 8.293c.415 0 .75-.335.75-.75s-.335-.75-.75-.75-.75.335-.75.75.335.75.75.75zm3 0c.415 0 .75-.335.75-.75s-.335-.75-.75-.75-.75.335-.75.75.335.75.75.75zm3 0c.415 0 .75-.335.75-.75s-.335-.75-.75-.75-.75.335-.75.75.335.75.75.75z"
                transform="translate(-183 -5577) translate(159 5312) translate(24 216) translate(0 49)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CommentsIcon
