const GreenCustomerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-275 -3441) translate(275 3441)">
        <circle cx="35" cy="35" r="35" fill="#DFF5EB" />
        <g fill="#41A476">
          <path
            d="M25.977 28.71c6.113 0 11.074-4.96 11.074-11.073 0-6.114-4.96-11.075-11.074-11.075s-11.075 4.96-11.075 11.075c0 6.114 4.96 11.074 11.075 11.074zm0-3.69c-4.069 0-7.383-3.315-7.383-7.383 0-4.069 3.314-7.383 7.383-7.383 4.068 0 7.382 3.314 7.382 7.383 0 4.068-3.314 7.382-7.382 7.382zm13.535 20.917c2.038 0 3.691-1.653 3.691-3.691v-1.969c0-5.706-4.63-10.336-10.336-10.336-2.207 0-3.268 1.23-6.89 1.23-3.623 0-4.676-1.23-6.891-1.23-5.706 0-10.336 4.63-10.336 10.336v1.969c0 2.038 1.653 3.691 3.691 3.691h27.07zm0-3.691h-27.07v-1.969c0-3.66 2.983-6.644 6.644-6.644 1.123 0 2.945 1.23 6.89 1.23 3.976 0 5.76-1.23 6.891-1.23 3.66 0 6.645 2.984 6.645 6.644v1.969z"
            transform="translate(8.75 8.75)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default GreenCustomerIcon
